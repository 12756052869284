<template>
  <div>
    <a-modal v-model="visible" :destroyOnClose="true" :maskClosable="false" centered @cancel="cancel" @ok="confirm">
      <div slot="title">{{ $t("整库位移库") }}</div>
      <div>
        <a-form-model ref="form" :model="dataForm" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="location_out" :label="$t('源库位')">
            <location-search-select v-model="dataForm.location_out" :warehouse="warehouse" @select="selectLocationOut" />
          </a-form-model-item>
          <a-form-model-item prop="location_in" :label="$t('目标库位')">
            <location-search-select v-model="dataForm.location_in" :warehouse="warehouse" @select="selectLocationIn" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { subBatchOption } from "@/api/option";
import { rules } from "./rules";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/"),
  },
  props: ["visible", "warehouse"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      dataForm: {},
    };
  },
  methods: {
    selectLocationOut(item) {
      this.dataForm.location_out_number = item.number;
    },
    selectLocationIn(item) {
      this.dataForm.location_in_number = item.number;
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          subBatchOption({ location: this.dataForm.location_out, page_size: 999999 }).then((data) => {
            for (const item of data.results) {
              this.$emit("add", {
                type: "location_transfer",
                material_number: item.material_number,
                material_name: item.material_name,
                status_display: item.status_display,
                batch_number: item.batch_number,
                sub_batch_out: item.id,
                location_out: this.dataForm.location_out,
                location_out_number: this.dataForm.location_out_number,
                location_in: this.dataForm.location_in,
                location_in_number: this.dataForm.location_in_number,
                transfer_quantity: item.remain_quantity,
                remain_quantity: item.remain_quantity,
              });
            }
            this.cancel();
          });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.resetForm();
    },
    resetForm() {
      this.dataForm = {
        type: "location_transfer",
        // material_number: "",
        // material_name: "",
        // status_display: "",
        // batch_number: "",
        // sub_batch_out: undefined,
        location_out: undefined,
        location_out_number: "",
        location_in: undefined,
        location_in_number: "",
        // transfer_quantity: 1,
        // remain_quantity: 0,
      };
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped></style>
